import {createContext, useState, useEffect, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import axios from "axios";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    let at = localStorage.getItem("authTokens");
    const perm = JSON.parse(localStorage.getItem("permissions"));

    const [authTokens, setAuthTokens] = useState(() => at ? JSON.parse(at) : null);
    const [user, setUser] = useState(() => at ? jwt_decode(at) : null);
    const [permissions, setPermissions] = useState(perm || [])
    const history = useHistory();

    const authApi = axios.create({
        baseURL: baseUrl,
        headers: {"Content-Type": "application/json"}
    })

    const setAuthStorage = (data) => {
        setAuthTokens(data);
        const u = jwt_decode(data.access);
        setUser(u);
        setPermissions(u.permissions);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", JSON.stringify(u?.permissions));
    }

    const loginUser = async (e) => {
        e.preventDefault()
        try {
            const resp = await authApi.post('api/token/', JSON.stringify({
                email: e.target.username.value,
                password: e.target.password.value
            }))
            if (resp.status === 200) {
                setAuthStorage(resp.data)
                history.push("/")
            }
            else alert("Hiba, helytelen email vagy jelszó!")
        } catch (e) {
            console.log(e)
            alert('Váratlan hiba!')
        }
    }

    const logoutUser = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        setPermissions(null);
        localStorage.removeItem("authTokens");
        localStorage.removeItem("permissions");
    }, []);

    const updateToken = useCallback(async () => {
        const resp = await authApi.post('api/token/refresh/', JSON.stringify({refresh: authTokens?.refresh}))
        if (resp.status === 200) setAuthStorage(resp.data)
        else logoutUser()
    }, [authTokens?.refresh, logoutUser]);

    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        permissions: permissions,
    };

    useEffect(() => {
        const fourMinutes = 1000 * 60 * 4;

        const interval = setInterval(() => {
            if (authTokens) {
                updateToken()
            }
        }, fourMinutes);
        return () => clearInterval(interval);
    }, [authTokens, updateToken]);

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};