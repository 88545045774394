import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import axios from 'axios';
import ErrorContext from "../context/ErrorContext";
import AuthContext from "../context/AuthContext";
import {useHistory} from "react-router-dom";
import {ensureTrailingSlash} from "../utils/miscUtils";
// import getFieldName from "../utils/fieldNames";

function useCallDataApi(model) {
    const history = useHistory()
    const {headers, formDataHeaders} = useAuthHeader();
    const {logoutUser} = useContext(AuthContext)
    const {baseUrl} = useContext(ConfigContext);
    const {setSubscriptionError, setPlanError, setUnexpectedError, setNetworkError, setPermissionError, setExpectedError} = useContext(ErrorContext)

    const url = baseUrl + '/' + model + '/';

    const authenticatedAPI = axios.create({
        baseURL: url,
        headers: headers
    })

    const formDataAPI = axios.create({
        baseURL: url,
        headers: formDataHeaders
    })

    const errorMap = {
        '1': () => setSubscriptionError(true),
        '2': () => setPlanError(true),
        '3': () => setPermissionError(true),
        undefined: () => setUnexpectedError(true),
    }

    const handleError = (e) => {
        console.log(e)
        if (e?.code === 'ERR_NETWORK') setNetworkError(true)
        if (e.response.status === 401) {
            logoutUser()
            return
        }
        if (e.response.status === 404) {
            console.log('Error 404')
            return
        }
        console.log(e.response?.data)
        try {
            JSON.stringify(e.response?.data)
            const message = []
            // for (let key of Object.keys(e.response?.data)) {
            //     message.push(`${getFieldName(key)}: ${e.response?.data[key]}`)
            // }
            setExpectedError({error: true, message: message})
        } catch (e) {
            errorMap[e.response?.data?.detail]()
        }
    }

    const createData = useCallback((data) => {
            return  authenticatedAPI.post("", JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const postData = useCallback((slug, data) => {
            return  authenticatedAPI.post(ensureTrailingSlash(slug), JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const getData = useCallback((id = null) => {
            return authenticatedAPI.get(ensureTrailingSlash(id))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const getDataPagination = useCallback((page = 1, limit = 50) => {
            return authenticatedAPI.get(`?page=${page}&limit=${limit}`)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const updateData = useCallback((id, data) => {
            return authenticatedAPI.patch(ensureTrailingSlash(id), JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))

        },
        [authenticatedAPI]
    );

    const deleteData = useCallback((id) => {
            return  authenticatedAPI.delete(ensureTrailingSlash(id))
                .then(r => r)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    // ************** FORM DATA STUFF **************

    const formDataCreateData = useCallback((data) => {
            return  formDataAPI.post("", data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    const formDataUpdateData = useCallback((id, data) => {
            return formDataAPI.patch(ensureTrailingSlash(id), data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    const getFile = useCallback(
        async (id, extension, name='data') => {
            const response = await fetch(ensureTrailingSlash(url + id), {
                method: 'GET',
                headers,
            });
            const blob = await response?.blob();
            if (response.status === 401) {
                logoutUser();
            }
            if (response.status === 403) {
                history.push("/");
            }
            if (response.status === 200) {
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = `${name}.${extension}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
            return response;
        },
        [headers, logoutUser]
    );

    return {
        getData,
        createData,
        updateData,
        deleteData,
        postData,
        formDataCreateData,
        formDataUpdateData,
        getDataPagination,
        getFile
    };
}

export default useCallDataApi;