const stringToColor = string => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const ensureTrailingSlash = url => {
    if (url) {
        const strUrl = url?.toString()
        return strUrl?.endsWith('/') ? strUrl : strUrl + '/';
    }
    return url
}


const getYears = () => {
    const today = new Date()
    const result = []
    for (let i = 2023; i <= today.getFullYear(); i++) {
        result.push(i)
    }
    return result
}

const monthNames = [
    "Január", "Február", "Március", "Április", "Május", "Június",
    "Július", "Augusztus", "Szeptember", "Október", "November", "December"
];

const getMonthName = (number) => {
    return monthNames[number - 1]
}

export {stringAvatar, stringToColor, getYears, getMonthName, ensureTrailingSlash}